.offerings-title {
  margin-top: 4rem;
  text-align: center;
}

.card-image {
  width: 100%;

  height: 15rem;
  object-fit: cover;
}

.main-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  margin-top: 3rem;
}

.card {
  background-color: white;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  width: 22%;
  margin: 1rem;
  overflow: hidden;
}

.name {
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0.5rem 0;
  text-align: center;
}

.card__para {
  font-size: 1rem;
  color: #7f8c9b;
  text-align: center;
  line-height: 150%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card__details {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
}

.card__button {
  margin: 2rem auto 1rem auto;
}

@media (max-width: 1024px) {
  .card {
    width: 30%;
  }
}
@media (max-width: 768px) {
  .card {
    width: 45%;
  }
}
@media (max-width: 768px) {
  .card {
    width: 70%;
  }
}
