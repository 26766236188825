.footer-images {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  gap: 20px;
}
.footer-icon {
  height: 2rem;
  width: 2rem;
  color: white;
  margin-bottom: 4rem;
}

.footer-title {
  text-align: center;
  font-size: 3rem;
  padding-top: 1em;
  margin-top: 3rem;
  margin-bottom: 8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
}
.footer-logo {
  width: 2.5em;
  height: 2.5em;
}

.footer-main {
  border-top: 0.4rem solid rgba(85, 197, 122, 0.9);
  background-color: #333;
}
.footer-last {
  margin: 0 1.5em 0 1.5em;
  padding-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
}
.footer-last p {
  color: white;
  font-size: 1rem;
}

@media (max-width: 435px) {
  .footer-last {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
