.offering__background {
  background-color: #ededed;
}

.offering__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.offering__title {
  padding: 2rem 0 4rem 0;
}

.offering__section {
  text-align: center;
  white-space: pre-wrap;
  margin: 0 auto;
}

.offering__heading {
  padding-bottom: 1rem;
  width: 100%;
}

.offering__italic {
  font-style: italic;
  color: rgb(88, 88, 88);
}

.offering__img {
  position: relative;
  overflow: hidden;
  width: 42%;
  height: 100%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  margin: 2rem 0;
}

.offering__width {
  width: 70%;
  padding: 0 2rem 0 1rem;
}

@media only screen and (max-width: 768px) {
  .offering__container {
    flex-direction: column;
  }

  .offering__width {
    width: 100%;
    padding: 1rem 2rem;
  }

  .offering__title {
    padding: 2rem 0 2rem 0;
  }
}

@media only screen and (max-width: 480px) {
  .offering__img {
    width: 70%;
    height: 100%;
  }
}
