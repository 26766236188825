.testimonial__heading {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.testimonial__slider {
  padding: 4rem;
  margin: 0 auto;
  text-align: center;
}

.testimonial__card {
  text-align: center;
}
.testimonial__content {
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.2em;
  letter-spacing: 0.05rem;
  font-size: 1.2rem;
}

.test-text {
  margin: 0;
  line-height: 1.5rem;
}

@media (min-width: 425px) and (max-width: 650px) {
  .test-text,
  .testimonial__content {
    font-size: 1rem;
  }
  .testimonial__slider {
    padding: 2rem;
  }
}

@media (min-width: 250px) and (max-width: 424px) {
  .test-text,
  .testimonial__content {
    font-size: 0.8rem;
  }
  .testimonial__content {
    width: 80%;
  }
  .testimonial__slider {
    padding: 2rem;
  }
}
