.slick-prev {
  left: 0;
  color: black;
  background: white;
}
.slick-next {
  right: 0;
  color: black;
  background-color: white;
}
.slick-slider {
  display: flex;
  justify-content: center;
}
