*,
html,
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

h1 {
  font-family: "Courgette", cursive;

  font-size: 3rem;
}

a {
  text-decoration: none;
  color: currentColor;
}

a:hover {
  color: black;
}

.navbar-nav {
  align-items: center;
  font-size: 1rem;
}

.nav-link {
  margin: 0 0.5rem;
  letter-spacing: 1px;
}

button {
  /* border: none;
  padding: 12px 24px;
  border-radius: 50px;
  font-weight: 400;
  background-color: #56cfa778;
  color: black;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transition: 0.2s all ease-in;
  display: block;
  cursor: pointer; */
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  background-color: #239248;
  color: #fff;
}

button:focus,
button:hover {
  /* transform: translateY(-4px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4); */
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgb(0 0 0 / 20%);
}
.btn--white {
  background-color: white;
  color: #777;
}

@media (max-width: 1440px) {
  .nav-link {
    white-space: nowrap;
    margin: 0 0.25rem;
    letter-spacing: 1px;
    font-size: 0.9rem;
  }
  .container {
    padding-left: 0;
  }
}
