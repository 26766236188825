.hero__quote {
  position: absolute;
  top: 20%;
  left: 8%;
  color: black;
}

.hero-img {
  overflow: hidden;
}

.hero__font {
  font-size: 3rem;
  width: 45%;
}

.btn__cta {
  /* margin-left: 2rem; */
  margin-top: 3rem;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 1.5rem;
}

@media (max-width: 1024px) {
  .hero__font {
    font-size: 2.5rem;
    width: 45%;
  }
  .btn__cta {
    padding: 1.25rem;
  }
}
@media (max-width: 768px) {
  .hero__font {
    font-size: 1.6rem;
    line-height: 1.6;
  }
  .btn__cta {
    padding: 0.75rem;
    font-size: 0.8rem;
    margin-top: 2rem;
  }
}

@media (max-width: 571px) {
  .hero__font {
    font-size: 1.2rem;
    line-height: 1.3;
  }
  .btn__cta {
    padding: 0.55rem;
    font-size: 0.6rem;
    margin-top: 1.4rem;
  }
}

@media (max-width: 425px) {
  .hero__font {
    font-size: 1rem;
    line-height: 1.3;
  }
  .btn__cta {
    padding: 0.35rem;
    font-size: 0.5rem;
    margin-top: 0.5rem;
  }
}
