.nav-item:hover .dropdown-menu {
  display: block !important;
}

.nav-color {
  /* background-color: #f9f6f9; */
  font-size: 1.4rem;
}

.logo-img {
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
  border-radius: 50%;
}

.heading {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  margin-bottom: 0;
}

.nav__button {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0.75rem;
}

@media (max-width: 1440px) {
  .logo-img {
    margin-right: 0.5rem;
  }
  .heading {
    font-size: 1.25rem;
    letter-spacing: 2px;
  }

  .nav__button {
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0.75rem;
  }
}
