.experience {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 0;
}
.page-title {
  font-size: 4rem;
}
.experience__container {
  margin-top: 1rem;
  position: relative;
  /* width: 100vw; */
  height: 100%;
}

.back__banner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
}

.about__card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 42%;
  text-align: left;
  padding: 1.2rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(#000, 0.2rem);
  background-color: white;
}
.card__title {
  font-size: 1.7rem;
  font-weight: bold;
}
.card__company {
  font-size: 1rem;
  color: #28b485;
}
.card__desc {
  font-size: 0.8rem;
  line-height: 1.6;
  color: rgb(139, 136, 136);
}
@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.4;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
.dot__animate {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  background-color: #6293af;
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
  opacity: 1;
}
.dot__fixed {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #28b485;
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
  opacity: 1;
  z-index: 999;
}
.year {
  position: absolute;
  color: #a4a0a0;
  top: -2.2rem;
  right: -0.5rem;
  font-size: 3.5rem;
  font-weight: bold;
}
.line {
  background-color: rgba(255, 255, 255, 0.4);
  width: 0.2rem;
  height: 100%;
}
.line__container {
  width: 2rem;
  height: 7rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
