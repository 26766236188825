.content-body {
  background-color: #ededed;
}
p {
  font-size: 1rem;
  color: black;
}

.h1-work {
  margin-top: 1rem;
  margin-right: 15px;
}

span {
  font-weight: bold;
}

.title-investment {
  font-size: 1.5rem;
}
.work-section {
  text-align: center;
  white-space: pre-wrap;
  margin: 0 8rem;
}
#working-with-me {
  margin-top: 4rem;
  padding-top: 3rem;
}

.sub-head {
  padding-bottom: 1rem;
  width: 100%;
}

.work__img {
  position: relative;
  overflow: hidden;
  width: 30%;
  height: 25rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  margin: 2rem 0;
}

.invest-section-img {
  width: 100%;
}

.content__width {
  width: 70%;
}

@media (max-width: 1024px) {
  .work__img {
    height: 20rem;
  }
}

@media (max-width: 768px) {
  .content__width {
    width: 55%;
  }
  .work__img {
    height: 17rem;
  }
  .sub-head {
    white-space: nowrap;
  }
}

@media (max-width: 660px) {
  .con-right {
    flex-direction: column;
  }
  .con-left {
    flex-direction: column-reverse;
  }
  .content__width {
    width: 100%;
  }
  .work__img {
    height: 15rem;
    width: 60%;
  }
  .work-section {
    margin: 1rem 4rem;
  }
  .sub-head {
    white-space: inherit;
    text-align: center;
    line-height: 1.5;
  }
}

@media (max-width: 550px) {
  .work-section {
    margin: 0.5rem 1rem;
  }
}
