.full-section {
  position: relative;
  overflow: hidden;
}

.hero-content {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #000;
  font-weight: 500;
  width: 60%;
}

.img__div {
  position: relative;
  overflow: hidden;
  width: 40%;
  padding: 4rem 2rem;
}

.side-image {
  width: 100%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.back__video {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  z-index: -1;
  opacity: 0.2;
}

@media (max-width: 991px) {
  .hero-content {
    padding: 2rem;
  }
  .hero-content > h1 {
    font-size: 2rem;
  }
  .hero-content > p {
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
  .main-container {
    height: 70vh;
  }
  .back__video {
    height: 70vh;
  }
}

@media (max-width: 768px) {
  .main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .img__div {
    width: 60%;
    height: 100%;
    overflow: visible;
  }
  .back__video {
    height: 100%;
  }
  .hero-content {
    width: 75%;
    align-items: center;
    text-align: center;
  }
  .img__div {
    padding: 4rem 0rem 2rem 0rem;
  }
}

@media (max-width: 440px) {
  button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media (min-width: 1024px) {
  .back__video {
    height: auto;
  }
}
